import React from 'react'
import { ProgressBar } from 'retro-react'
export default function Loader({ time }) {
    return (
        <div
            style={{
                height: '20px',
                width: '500px'
            }}
        >
            <ProgressBar
                pattern="stripes"
                value={time}
            />
        </div>
    )
}
