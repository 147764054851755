import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import Loader from './components/Loader';
import { Box, Flex, MouseTrail, Container, Statistics, WordArt, Button, Marquee } from 'retro-react';

function App() {
  const [time, setTime] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Augmenter time jusqu'à 100 en 3 secondes
    const interval = setInterval(() => {
      setTime(prevTime => {
        if (prevTime >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prevTime + 100 / 30; // 100 divisé par le nombre d'intervalles en 3 secondes
      });
    }, 100); // 3000ms / 30

    // Attendre 3 secondes avant de changer l'affichage
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Box color="primary"
      pattern="solid"
      sx={{
        width: '100%'
      }}>
      <MouseTrail
        offset={{
          x: 0,
          y: 0
        }}
        particleColor="secondary"
        particleSize={3}
      />
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", minHeight: "100vh" }}>
        {loading ? <Loader time={time} /> :
          <div className="ui" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <img src="/windows.png" style={{ width: "200px", margin: "auto" }} />
            <WordArt
              artStyle="curved"
              color="rainbow"
              rotation={0}
              sx={{margin: "50px 0px"}}
              size="large"
            >
              ths best os, isn't it ?
            </WordArt>
            <div style={{margin: "50px 0px"}} sx={{ display: "flex", justifyContent: "spaceBetween" }}>
              <Button
                color="secondary"
                isButtonGroup
                disabled
                onClick={function noRefCheck() { }}
                size="small"
                sx={{ marginRight: '1rem' }}
                variant="solid"
              >
                <a href="twitter">
                  twitter
                </a>
              </Button>
              <Button
                color="alert"
                isButtonGroup
                onClick={function noRefCheck() { }}
                size="small"
                sx={{ marginRight: '1rem' }}
                variant="alert"
              >
                <a href="telegram">
                  telegram
                </a>
              </Button>

              <Button
                color="secondary"
                isButtonGroup
                disabled
                onClick={function noRefCheck() { }}
                size="small"
                sx={{ marginRight: '1rem' }}
                variant="solid"
              >
                <a href="buy">
                  buy
                </a>
              </Button>
              <Button
                color="secondary"
                disabled
                isButtonGroup
                onClick={function noRefCheck() { }}
                size="small"
                sx={{ marginRight: '1rem' }}
                variant="solid"
              >
                <a href="chart">
                  chart
                </a>
              </Button>
            </div>
            <Container>
              <Box color={"success"}>
              <Statistics
                color="secondary"
                direction="vertical"
                label="Supply"
                number="95000000"
                onClick={function noRefCheck() { }}
                size="small"
              />
              <Statistics
                color="success"
                direction="vertical"
                label="Wen"
                number="?"
                onClick={function noRefCheck() { }}
                size="small"
              />
                <Statistics
                 color="primary"
                 direction="vertical"
                 label="Utility"
                 number="TBA"
                 onClick={function noRefCheck() { }}
                 size="small"
               />
              <Statistics
                color="greyscale"
                direction="vertical"
                label="Tax"
                number="1%"
                onClick={function noRefCheck() { }}
                size="small"
              />
                </Box>
            </Container>

            <Container>
            <div
              style={{
                background: '#ff5f28',
                color: 'black',
                opacity: 0.6,
                margin: "25px 0px",
                padding: "10px"
              }}
            >
              <div style={{textAlign: "center"}}>CA: soon</div><br/>
              <Box color={"success"}>
              Token Name: $WIN
              </Box>
              <br />
              <Box color={"success"}>
              Type: SPL-Token 2022
              </Box>
              <br/>
              <Box color={"success"}>
              Year of Creation: 2024
              </Box>
              <br />
              <Box color={"success"}>
              Network: Solana Blockchain

              <br /><br />
              Description:<br /><br />
              $WIN is a distinctive <strong>SPL-Token crafted on the Solana blockchain</strong>, known for its efficiency and low transaction costs. Created in 2022, this meme token is a playful yet innovative addition to the crypto world, combining humor with the robust technology of Solana.
              <br /><br />
              The token features a unique taxation system, where each transaction incurs a nominal tax of 1%. This tax mechanism is designed to encourage holding and reduce the frequency of speculative trading, fostering a more stable and community-focused environment.
              <br /><br />
              As a meme token, $WIN encapsulates the spirit of the internet culture, making it appealing to a wide range of users, especially those who appreciate the lighter side of the crypto universe.<br /> Its creation underscores the versatility of the Solana ecosystem, demonstrating its capacity to support not only serious financial instruments but also fun, community-driven projects.
              <br /><br />
              With its engaging concept and user-friendly approach, $WIN aims to not only be a part of the crypto conversation but also to add a touch of humor and lightheartedness to the world of digital assets.
              <br /><br />
              </Box>
              <br/>
              <Box color={"success"}>

              Key Features:
              <br /><br />
              Built on Solana, ensuring fast and cost-effective transactions.<br />
              A unique 1% transaction tax to promote stability and community engagement.<br />
              Meme-centric, appealing to a broad, internet-savvy audience.<br />
              Symbolizes the blend of fun and functionality in the crypto space.<br />
              $WIN is more than just a digital asset; it's a symbol of the vibrant and ever-evolving crypto community, looking to make a lasting impact in the digital world with a smile.<br />
              </Box>

            </div>
            </Container>

            <Marquee
              color="#000000"
              gap="1rem"
              size="medium"
              speed="20s"
            >
              <img style={{ maxWidth: "200px", width: "100%" }} src="meme.jpg" />
              <img style={{ maxWidth: "200px", width: "100%" }} src="windows.png" />
              <img style={{ maxWidth: "200px", width: "100%" }} src="c91.jpg" />
            </Marquee>
          </div>
        }
      </div>
    </Box>
  );
}

export default App;
